//===================================================
//	color
//===================================================
// メインカラー
$main_c:    #f2a543;//サイトメインカラー
$sub_c:     #f6c381;//サイトサブカラー
$point_c:   #744613;//ポイントカラー
$txt_c:     #1c1c1c;//テキストカラー
$point_txt: #5D4D1C;
$link_c:    $main_c;//リンク基本カラー
$sub_bg: #e3f1fa; //サイトバックサブカラー
$background_C: #fffdf2; //背景色

// hover
$main_ch:   #47d08c;//サイトメインカラー ホバー時
$red_h:     #df2f2f;

// mono tone
$white:     #fff;   //ホワイト
$o-white:   #fcfcfc;//オフホワイト
$p-gray:    #f3f3f3;   //ペールグレー
$l-gray:    #ddd;   //ライトグレー
$m-gray:    #ccc;   //ミディアムグレー
$gray:      #bbb;   //グレー
$dl-gray:   #999;   //ダルグレー
$d-gray:    #666;   //ダークグレー
$b-gray:    #333;   //ブラックグレー
$black:     #000;   //ブラック

// color tone
$red:       #df2f2f;//レッド
$red2:      #eb4c4c;//レッド2
$orange:    #ff8b2d;//オレンジ
$yellow:    #ffcf27;//イエロー
$y-green:   #01a63d;//イエローグリーン
$green:     #5d9e36;//グリーン
$s-blue:    #e6f0fe;//スカイブルー
$blue:      #2b74e0;//ブル-
$violet:    #425c93;//バイオレット
$purple:    #5c0c7a;//パープル
$pink:      #ca6a51;//ピンク

// 明度調整
$palettes: (
	main_c: (
		base:   $main_c,
		light:  lighten($main_c, 10%),
		dark:   darken($main_c, 10%)
		),
	sub_c: (
		base:   $sub_c,
		light:  lighten($sub_c, 10%),
		dark:   darken($sub_c, 10%)
		),
	point_c: (
		base:   $point_c,
		light:  lighten($point_c, 10%),
		dark:   darken($point_c, 10%)
		),
	grey: (
		base:  $gray,
		light: lighten($gray, 10%),
		dark:  darken($gray, 10%)
		)
	);
@function palettes($key, $tone: base) {
	@return map-get( map-get($palettes, $key), $tone);
}