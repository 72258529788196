@charset "UTF-8";
/*	flex
---------------------------------------------------*/
/* aspect */
/* ofi */
@media print, screen and (min-width: 1920px) {
  /*====================================================================/
  	/*-------------------full PCスタイル-------------------
  	/====================================================================*/
  html {
    width: 100%;
  }
}
@media print, screen and (min-width: 1257px) {
  /*====================================================================/
  	/*-------------------PCスタイル-------------------
  	/====================================================================*/
  html {
    width: 100%;
  }
}
@media print, screen and (min-width: 977px) {
  /*====================================================================/
  	/*-------------------小さいPCスタイル----------------
  	/====================================================================*/
  html {
    width: 100%;
  }
}
@media print, screen and (min-width: 561px) { /*====================================================================/
	/*------------------タブレットスタイル----------------
	/====================================================================*/
  html {
    width: 100%;
  }
}
@media print, screen and (min-width: 376px) {
  /*====================================================================/
  	/*------------------iPhone 以上----------------
  	/====================================================================*/
  html {
    width: 100%;
  }
}
@media screen and (max-width: 1256px) {
  /*====================================================================/
  	/*------------------PC以下---------
  	/====================================================================*/
  html {
    width: 100%;
  }
}
@media screen and (max-width: 976px) {
  /*====================================================================/
  	/*------------------スマホタブレット共通スタイル---------
  	/====================================================================*/
  html {
    width: 100%;
  }
}
@media screen and (max-width: 560px) {
  /*====================================================================/
  	/*------------------スマホのみスタイル----------------
  	/====================================================================*/
  html {
    width: 100%;
  }
}
@media screen and (max-width: 375px) {
  /*====================================================================/
  	/*------------------iPhone 以下----------------
  	/====================================================================*/
  html {
    width: 100%;
  }
}
/*--------------------------------------------------------------------/
	breadcrumb__list
/--------------------------------------------------------------------*/
.breadcrumb__list {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  margin-bottom: 2rem;
  font-size: 0.9em;
}
@media print, screen and (min-width: 977px) {
  .breadcrumb__list {
    margin-bottom: 4rem;
  }
}

.breadcrumb__list .icom-home {
  font-size: 1.2em;
}

.breadcrumb__list a {
  color: #1c1c1c;
  transition: 0.3s ease all;
  vertical-align: top;
}
.breadcrumb__list a:hover {
  color: #f2a543;
}

.cont__inner .breadcrumb__list a,
.works .breadcrumb__list a {
  color: #fff;
}
.cont__inner .breadcrumb__list a:hover,
.works .breadcrumb__list a:hover {
  color: #f2a543;
}

/*--------------------------------------------------------------------/
	contact
/--------------------------------------------------------------------*/
/* contact  */
.contact {
  color: #fff;
  padding: 0;
  width: 100%;
  position: relative;
  background: transparent;
}
.contact::before {
  background: url(../img/bg_03_pc.jpg) 50% top no-repeat;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  z-index: -1;
}
.contact .l-cont {
  position: relative;
}
@media print, screen and (min-width: 1257px) {
  .contact-flex {
    display: flex;
    justify-content: space-between;
  }
  .contact-flex__left {
    width: 36%;
    margin-left: 0;
  }
  .contact-flex__right {
    width: 60%;
    margin-right: 0;
  }
}
.contact .c-box__tit {
  padding-bottom: 0.8rem;
  border-bottom: solid #fff 1px;
  margin: 0 auto 1.5rem;
}
@media print, screen and (min-width: 1257px) {
  .contact .c-box__tit {
    font-size: 2.4rem;
  }
}
@media screen and (max-width: 976px) {
  .contact .c-box__tit {
    text-align: left;
  }
}
.contact__tel {
  margin-bottom: 2rem;
  font-size: calc(2rem + 6 * (100vw - 320px) / 680);
}
@media print, screen and (min-width: 1257px) {
  .contact__tel {
    font-size: 2.6rem;
  }
}
.contact__tel a {
  color: #fff !important;
  text-decoration: none !important;
  vertical-align: bottom;
}

.privacy-box {
  padding: 1.5rem;
  height: 150px;
  margin: 0 auto 2rem;
  overflow-y: auto;
  border: 1px solid #ddd;
}
@media print, screen and (min-width: 977px) {
  .privacy-box {
    padding: 1.5rem 2rem;
    height: 200px;
  }
}

/*	contact_form
---------------------------------------------------------------------*/
.contact__table {
  margin: 0 auto 2rem;
  width: 100%;
  line-height: 1.5;
}
@media print, screen and (min-width: 977px) {
  .contact__table {
    display: table;
  }
}
.contact__table th, .contact__table td {
  font-size: 1.4rem;
  font-weight: normal;
  width: 100%;
  display: block;
  vertical-align: middle;
}
@media print, screen and (min-width: 977px) {
  .contact__table th, .contact__table td {
    display: table-cell;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}
@media print, screen and (min-width: 977px) {
  .contact__table tr:not(:last-child) {
    border-bottom: 1px solid #ddd;
  }
}
.contact__table th {
  text-align: left;
  color: #fff;
  padding-bottom: 0.5rem;
}
@media print, screen and (min-width: 977px) {
  .contact__table th {
    width: 30%;
  }
}
.contact__table th span {
  color: #ff3434;
  padding: 0 0.2rem;
  margin-left: 0.5rem;
}
.contact__table td {
  padding-bottom: 1.5rem;
}
@media print, screen and (min-width: 977px) {
  .contact__table td {
    width: 70%;
  }
}
.contact__table textarea {
  height: 10em;
}

/* form-parts
----------------------------------------------------------------*/
input,
textarea,
select {
  background-color: #f6f7f8;
  font-size: 14px;
  font-size: 1.4rem;
  width: 100%;
  margin: 3px 0;
  padding: 0.5em;
  border: 1px solid #ddd;
  border-radius: 2px;
}

textarea {
  font-family: sans-serif;
}

input:focus,
textarea:focus {
  background-color: #fff;
  /* box-shadow */
  box-shadow: rgba(58, 134, 232, 0.25) 0 0 4px 1px;
}

:placeholder-shown {
  color: #ccc;
}

::-webkit-input-placeholder {
  color: #ccc;
}

::-moz-placeholder {
  color: #ccc;
}

:-ms-input-placeholder {
  color: #ccc;
}

input[type=submit] {
  -webkit-appearance: none;
}

input[class=sub-btn] {
  display: block;
  width: 100px;
  margin: 0 auto;
  padding: 1rem 0;
  position: relative;
  transition: 0.3s ease all;
  text-align: center;
  text-decoration: none;
  letter-spacing: 2px;
  border-radius: 3px;
  color: #fff;
  background: #f2a543;
  border-color: #f2a543;
}
input[class=sub-btn]:hover {
  color: #fff;
  background: #f6c381;
  border: 1px solid #f6c381;
}

input[class=back-btn] {
  margin-top: 1rem;
  display: block;
  width: 100px;
  margin: 0 auto;
  padding: 1rem 0;
  position: relative;
  transition: 0.3s ease all;
  text-align: center;
  text-decoration: none;
  letter-spacing: 2px;
  border-radius: 3px;
  color: #f2a543;
  background: #fff;
  border-color: #f2a543;
}
input[class=back-btn]:hover {
  color: #fff;
  background: #f6c381;
  border: 1px solid #f6c381;
}

input[type=radio],
input[type=checkbox] {
  display: inline;
  width: 20px;
  vertical-align: top;
}

.radio,
.checkbox {
  transition: 0.3s ease all;
  position: relative;
  display: inline-block;
  margin: 0 20px 8px 0;
  padding: 12px 12px 12px 42px;
  border-radius: 3px;
  background-color: #2b74e0;
  vertical-align: middle;
  cursor: pointer;
}

.radio:hover,
.checkbox:hover {
  background-color: #a8a8f4;
}

.radio:hover:after,
.checkbox:hover:after {
  border-color: #f6c381;
}

.radio:after,
.checkbox:after {
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  position: absolute;
  top: 50%;
  left: 16px;
  display: block;
  margin-top: -8px;
  width: 16px;
  height: 16px;
  border: 1px solid #f2a543;
  border-radius: 6px;
  content: "";
}

.radio:before {
  transition: all 0.3s ease;
  position: absolute;
  top: 50%;
  left: 19px;
  display: block;
  margin-top: -6px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #2b74e0;
  content: "";
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}

input[type=radio]:checked + .radio:before {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.checkbox:before {
  transition: all 0.3s ease;
  position: absolute;
  top: 50%;
  left: 21px;
  display: block;
  margin-top: -5px;
  width: 5px;
  height: 9px;
  border-right: 3px solid #f6c381;
  border-bottom: 3px solid #f6c381;
  content: "";
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

input[type=checkbox]:checked + .checkbox:before {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.no-csstransforms input[type=radio],
.no-csstransforms input[type=checkbox] {
  display: inline;
}

.no-csstransforms .radio,
.no-csstransforms .checkbox {
  display: none;
}

.no-csstransforms .checkbox:before {
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  position: absolute;
  top: 50%;
  left: 21px;
  display: block;
  margin-top: -5px;
  width: 5px;
  height: 9px;
  border-right: 3px solid #f6c381;
  border-bottom: 3px solid #f6c381;
  content: "";
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

input[type=checkbox]:checked + .no-csstransforms .checkbox:before {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input[class*=middle] {
  margin-right: 8px !important;
  margin-right: 0.5rem !important;
}

textarea {
  height: 10em;
}

select {
  width: 40%;
}

.form-short {
  width: 60%;
  margin-right: 0.5rem;
}
@media print, screen and (min-width: 561px) {
  .form-short {
    width: 40%;
  }
}

.form-middle {
  width: 70%;
  margin-right: 0.5rem;
}
@media screen and (max-width: 560px) {
  .form-middle {
    width: 100%;
  }
}

/*--------------------------------------------------------------------/
	works
/--------------------------------------------------------------------*/
/* works */
.works {
  color: #fff;
  padding: 0;
  width: 100%;
  position: relative;
  background: transparent;
}
.works::before {
  background: url(../img/bg_02_pc.jpg) 50% top no-repeat;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  z-index: -1;
}
.works .l-cont {
  position: relative;
}
@media print, screen and (min-width: 1257px) {
  .works__wrap {
    margin-left: auto;
    margin-right: auto;
  }
}

#works .btn__wrap {
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
  max-width: 400px;
}

.works-detail {
  margin-bottom: 3rem;
}
@media print, screen and (min-width: 977px) {
  .works-detail {
    margin-bottom: 6rem;
  }
}
.works-detail__tit {
  font-size: calc(1.6rem + 4 * (100vw - 320px) / 680);
  font-weight: 400;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #ddd;
  padding-bottom: 0.5rem;
}
@media print, screen and (min-width: 1257px) {
  .works-detail__tit {
    font-size: 2rem;
  }
}
.works-detail__cont {
  margin-bottom: 1.5rem;
}
.works-detail__slider {
  margin: 0 auto;
  text-align: left;
}
.works-detail__txt {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
@media print, screen and (min-width: 561px) {
  .works-detail__txt {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}
.works-detail__txt .txt {
  background: #f3f3f3;
  padding: 2rem 3rem;
}

.works-detail__slider {
  display: flex;
  width: 100%;
}
@media print, screen and (min-width: 977px) {
  .works-detail__slider {
    justify-content: space-between;
  }
}
@media screen and (max-width: 976px) {
  .works-detail__slider {
    flex-direction: column;
  }
}

.gallery-top {
  width: 100%;
  text-align: left;
  position: relative;
  overflow-x: hidden;
}
@media print, screen and (min-width: 977px) {
  .gallery-top {
    width: 75%;
  }
}
@media screen and (max-width: 976px) {
  .gallery-top {
    margin: 0 auto;
  }
}
.gallery-top ul {
  top: 0;
  left: 0;
  width: 100%;
  position: absolute;
}
.gallery-top ul li {
  width: 100%;
  float: left;
  display: inline;
  overflow-x: hidden;
}
.gallery-top figcaption {
  max-height: 6rem;
  overflow-y: auto;
}
@media print, screen and (min-width: 977px) {
  .gallery-top figcaption {
    max-height: 8rem;
    position: absolute;
    background: #000;
    color: #fff;
    display: inline-block;
    padding: 0.2rem 1rem;
    bottom: 0;
    left: 0;
  }
}
.gallery-top__img {
  background-color: #333;
  position: relative;
  width: 100%;
}
.gallery-top__img::before {
  content: "";
  display: block;
  padding-top: 66.66%;
  position: relative;
}
.gallery-top__img > div,
.gallery-top__img figure,
.gallery-top__img picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.gallery-top__img img {
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 100%;
}

.gallery-thumbs {
  width: 100%;
  overflow: hidden;
}
@media print, screen and (min-width: 977px) {
  .gallery-thumbs {
    width: 20%;
  }
}
@media screen and (max-width: 976px) {
  .gallery-thumbs {
    margin: 0 auto 1rem;
  }
}
.gallery-thumbs ul {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
@media print, screen and (min-width: 977px) {
  .gallery-thumbs ul {
    column-gap: 10%;
  }
}
@media screen and (max-width: 976px) {
  .gallery-thumbs ul {
    column-gap: 1.66%;
  }
}
.gallery-thumbs ul li {
  cursor: pointer;
  margin-bottom: 1rem;
  overflow: hidden;
}
@media print, screen and (min-width: 977px) {
  .gallery-thumbs ul li {
    margin-bottom: 1.6rem;
    width: 45%;
  }
}
@media screen and (max-width: 976px) {
  .gallery-thumbs ul li {
    width: 15.27%;
  }
}
.gallery-thumbs ul li img {
  width: 100%;
}
.gallery-thumbs ul li a {
  opacity: 0.7;
}
.gallery-thumbs ul li a.active {
  opacity: 1 !important;
}

.works-detail__main {
  max-width: 960px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 1rem;
}
@media print, screen and (min-width: 977px) {
  .works-detail__main {
    margin-bottom: 2rem;
  }
}
.works-detail__main__img {
  position: relative;
  width: 100%;
}
.works-detail__main__img::before {
  content: "";
  display: block;
  padding-top: 66.66%;
  position: relative;
}
.works-detail__main__img > div,
.works-detail__main__img figure,
.works-detail__main__img picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.works-detail__main__img img {
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 100%;
}

.pdf-cont {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
@media print, screen and (min-width: 561px) {
  .pdf-cont {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.works-video {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
@media print, screen and (min-width: 561px) {
  .works-video {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}
.works-video__tit {
  font-size: calc(1.6rem + 2 * (100vw - 320px) / 680);
  text-align: center;
  font-weight: 600;
  margin-bottom: 2rem;
}
@media print, screen and (min-width: 1257px) {
  .works-video__tit {
    font-size: 1.8rem;
  }
}
@media print, screen and (min-width: 977px) {
  .works-video__flex {
    display: flex;
    justify-content: space-between;
  }
}
@media print, screen and (min-width: 977px) {
  .works-video__video {
    width: 37.5%;
  }
}
@media screen and (max-width: 976px) {
  .works-video__video {
    margin-left: auto;
    margin-right: auto;
    max-width: 480px;
    margin-bottom: 1rem;
  }
}
.works-video__video__inner {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}
.works-video__video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media print, screen and (min-width: 977px) {
  .works-video__txt {
    width: 58%;
  }
}

.works-outline {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
@media print, screen and (min-width: 561px) {
  .works-outline {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}
.works-outline__tit {
  font-size: calc(1.6rem + 2 * (100vw - 320px) / 680);
  text-align: center;
  font-weight: 600;
  margin-bottom: 2rem;
}
@media print, screen and (min-width: 1257px) {
  .works-outline__tit {
    font-size: 1.8rem;
  }
}
.works-outline__table {
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  width: 100%;
}
@media print, screen and (min-width: 977px) {
  .works-outline__table {
    display: table;
    width: 100%;
  }
}
.works-outline__table th, .works-outline__table td {
  padding: 1rem 1.5rem;
  text-align: left;
  vertical-align: top;
  font-weight: normal;
  display: block;
  width: auto;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
@media print, screen and (min-width: 977px) {
  .works-outline__table th, .works-outline__table td {
    padding: 1.5rem 2.5rem;
    display: table-cell;
    width: auto;
    vertical-align: top;
    text-align: left;
  }
}
.works-outline__table th {
  font-weight: 600;
}
@media print, screen and (min-width: 977px) {
  .works-outline__table th {
    width: 30%;
  }
}
@media print, screen and (min-width: 977px) {
  .works-outline__table td {
    width: auto;
  }
}

.works-access {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
@media print, screen and (min-width: 561px) {
  .works-access {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}
.works-access__tit {
  font-size: calc(1.6rem + 2 * (100vw - 320px) / 680);
  text-align: center;
  font-weight: 600;
  margin-bottom: 2rem;
}
@media print, screen and (min-width: 1257px) {
  .works-access__tit {
    font-size: 1.8rem;
  }
}
.works-access__map {
  margin-bottom: 1rem;
  position: relative;
  padding: 0;
  height: 0;
  overflow: hidden;
  background-color: #fff;
}
@media print, screen and (min-width: 1257px) {
  .works-access__map {
    padding-bottom: 40%;
  }
}
@media screen and (max-width: 1256px) {
  .works-access__map {
    padding-bottom: 300px;
  }
}
.works-access__map iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

/*--------------------------------------------------------------------/
	blog
/--------------------------------------------------------------------*/
.blog__wrap {
  margin-bottom: 4rem;
}
.blog__wrap::after {
  display: block;
  content: "";
  clear: both;
}
@media print, screen and (min-width: 977px) {
  .blog__wrap {
    float: left;
    width: calc(96% - 220px);
  }
}
@media print, screen and (min-width: 1257px) {
  .blog__wrap {
    width: 74%;
  }
}

aside {
  border-radius: 3px;
  -webkit-border-radius: 3px;
  border: 1px solid #ddd;
  padding: 1.5rem;
}
@media print, screen and (min-width: 977px) {
  aside {
    width: 220px;
    float: right;
  }
}
@media print, screen and (min-width: 1257px) {
  aside {
    width: 22%;
  }
}

@media print, screen and (min-width: 977px) {
  .alignright {
    float: right;
    margin: 0 0 1rem 1rem;
  }
}

@media print, screen and (min-width: 977px) {
  .alignleft {
    float: left;
    margin: 1rem 1rem 0 0;
  }
}

@media print, screen and (min-width: 977px) {
  .aligncenter {
    display: block;
    margin: 0 auto 1rem;
  }
}

.blog-list:not(:last-child) {
  margin-bottom: 2rem;
}
.blog-list a {
  color: #1c1c1c;
  width: 100%;
  display: block;
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 1.25rem 2rem 2rem;
  text-decoration: none !important;
  height: 100%;
  overflow: hidden;
  transition: 0.3s ease all;
}
.blog-list a:hover {
  border-color: #f6c381;
}
.blog-list a:hover .blog-list__btn-wrap span {
  background: #f6c381;
  border-color: #f6c381;
}
.blog-list__tit {
  font-size: calc(1.6rem + 2 * (100vw - 320px) / 680);
  font-weight: 500;
  margin-bottom: 1rem;
  border-bottom: 2px solid #f2a543;
  padding-bottom: 1rem;
}
@media print, screen and (min-width: 1257px) {
  .blog-list__tit {
    font-size: 1.6rem;
  }
}
@media print, screen and (min-width: 977px) {
  .blog-list__flex {
    display: flex;
    justify-content: space-between;
  }
}
.blog-list__img {
  position: relative;
  width: 100%;
}
.blog-list__img::before {
  content: "";
  display: block;
  padding-top: 66.66%;
  position: relative;
}
.blog-list__img > div,
.blog-list__img figure,
.blog-list__img picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
@media print, screen and (min-width: 977px) {
  .blog-list__img {
    width: 30%;
    padding-right: 2%;
  }
}
@media screen and (max-width: 976px) {
  .blog-list__img {
    margin-bottom: 0.5rem;
  }
}
@media print, screen and (min-width: 977px) {
  .blog-list .txt {
    width: 68%;
  }
}
.blog-list p {
  margin-bottom: 1.5rem;
}
.blog-list .date {
  text-align: right;
}
.blog-list__btn-wrap {
  float: right;
  width: 50%;
}

.blog-cat__btn-wrap {
  margin-top: 2rem;
}
@media print, screen and (min-width: 977px) {
  .blog-cat__btn-wrap {
    float: right;
    width: 40%;
  }
}

.blog-single__meta {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
}
.blog-single__meta::after {
  display: block;
  content: "";
  clear: both;
}
.blog-single__meta .twitter, .blog-single__meta .fb-like.fb_iframe_widget {
  position: relative;
}
.blog-single__meta .twitter {
  z-index: 1;
  margin-right: 0.5rem;
}
.blog-single__meta iframe {
  padding: 0 !important;
  border: none !important;
  vertical-align: top;
}
.blog-single__meta .data {
  text-align: right;
}
.blog-single__post-thumbnail {
  margin-bottom: 1.6rem;
}
.blog-single .txt {
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: dotted #bbb 1px;
}
.blog-single .txt img {
  height: auto;
}
.blog-single__btn-wrap {
  max-width: 280px;
  margin-top: 4rem;
  margin-left: auto;
  margin-right: auto;
}
@media print, screen and (min-width: 977px) {
  .blog-single__btn-wrap {
    width: 30%;
  }
}

/* pdf ボタン　*/
.blog-pdf {
  margin-top: 1.5rem;
}
.blog-pdf a {
  text-decoration: none !important;
  padding: 0.8rem;
  border: 1px solid #bbb;
  color: #1c1c1c;
  background: #f3f3f3;
  padding-left: 4.8rem;
  display: block;
  position: relative;
  transition: 0.3s ease all;
}
.blog-pdf a:after {
  content: "";
  background: url(../img/pdf.svg) no-repeat;
  width: 22px;
  height: 22px;
  position: absolute;
  left: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
}
.blog-pdf a:hover {
  background: #425c93;
  color: #fff;
  border-color: #425c93;
}

.pdf-link {
  margin-top: 1.25rem;
  line-height: 1;
}
.pdf-link a {
  color: #1c1c1c;
}
.pdf-link a:hover p {
  text-decoration: none !important;
}
.pdf-link figure {
  display: inline-block;
  max-width: 158px;
  margin-right: 1rem;
  vertical-align: middle;
}
.pdf-link p {
  display: inline-block;
  text-decoration: underline;
}

/*-----------------------------------/
	widget
------------------------------------*/
.widget-blog-aside:not(:last-of-type) {
  margin-bottom: 3rem;
}
.widget-blog-aside__tit {
  color: #1c1c1c;
  margin-bottom: 1rem;
  background: #f6c381;
  padding: 0.5rem 1rem;
}
.widget-blog-aside li {
  list-style-type: none;
}
.widget-blog-aside li a {
  position: relative;
  padding-left: 2rem;
  display: block;
  transition: 0.3s ease all;
  color: #f2a543;
  text-decoration: underline;
}
.widget-blog-aside li a::before {
  content: "●";
  font-size: 0.7em;
  color: #f2a543;
  position: absolute;
  left: 0;
  top: 2px;
}
.widget-blog-aside li a:hover {
  color: #f6c381;
  text-decoration: none;
}

/*-----------------------------------/
	calendar
------------------------------------*/
#wp-calendar {
  border-collapse: collapse;
  width: 100%;
}
#wp-calendar caption {
  color: #1c1c1c;
}
#wp-calendar thead th {
  text-align: center;
  padding: 4px;
  width: auto;
}
#wp-calendar td {
  text-align: center;
  padding: 2px 4px;
}
#wp-calendar td a {
  color: #f2a543;
  transition: 0.3s ease all;
  display: block;
  text-decoration: none;
}
#wp-calendar td a:hover {
  color: #f6c381;
}
#wp-calendar td.pad {
  color: #f2a543;
}
#wp-calendar td#today a {
  border: 1px solid;
}

/*--------------------------------------------------------------------/
	記事部分
/--------------------------------------------------------------------*/
p.wp-caption-text {
  clear: none !important;
  padding: 5px 0 0 !important;
  font-size: 12px !important;
  text-align: center !important;
  line-height: 2 !important;
}

/*--------------------------------------------------------------------/
	page_next_prev
/--------------------------------------------------------------------*/
.c-page_next_prev {
  margin-top: 2rem;
  padding: 1rem 0;
  text-align: center;
  font-size: 1.6rem;
  color: #ddd;
}
.c-page_next_prev::after::after {
  display: block;
  content: "";
  clear: both;
}
.c-page_next_prev a {
  color: #ddd;
  text-decoration: none;
  transition: 0.3s ease all;
}
.c-page_next_prev a:hover {
  color: #f6c381;
}
.c-page_next_prev__inner {
  display: inline-block;
}
.works .c-page_next_prev {
  color: #fff;
}
.works .c-page_next_prev a {
  color: #fff;
}

.page-numbers {
  padding: 0.3em 0.5em;
  transition: 0.2s ease all;
}
span .page-numbers {
  border: 1px solid;
}

.nav-prev {
  float: left;
  margin-top: 24px;
  margin-top: 1.5rem;
}

.nav-next {
  float: right;
  margin-top: 24px;
  margin-top: 1.5rem;
}

/**
 * BxSlider v4.1.2 - Fully loaded, responsive content slider
 * http://bxslider.com
 *
 * Written by: Steven Wanderski, 2014
 * http://stevenwanderski.com
 * (while drinking Belgian ales and listening to jazz)
 *
 * CEO and founder of bxCreative, LTD
 * http://bxcreative.com
 */
/** RESET AND LAYOUT
===================================*/
.bx-wrapper {
  position: relative;
  margin: 0 auto 30px;
  padding: 0;
  *zoom: 1;
}

.bx-wrapper img {
  width: auto;
  display: block;
}

/** THEME
===================================*/
.bx-wrapper .bx-viewport {
  /*fix other elements on the page moving (on Chrome)*/
  -webkit-transform: translatez(0);
  -moz-transform: translatez(0);
  -ms-transform: translatez(0);
  -o-transform: translatez(0);
  transform: translatez(0);
}

.bx-wrapper .bx-pager,
.bx-wrapper .bx-controls-auto {
  position: absolute;
  bottom: -30px;
  width: 100%;
}

/* LOADER */
.bx-wrapper .bx-loading {
  min-height: 50px;
  background: url(../img/bx_loader.gif) center center no-repeat #fff;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2000;
}

/* PAGER */
.bx-wrapper .bx-pager {
  text-align: center;
  font-size: 0.85em;
  font-family: Arial;
  font-weight: bold;
  color: #666;
  padding-top: 20px;
}

.bx-wrapper .bx-pager .bx-pager-item,
.bx-wrapper .bx-controls-auto .bx-controls-auto-item {
  display: inline-block;
  *zoom: 1;
  *display: inline;
}

.bx-wrapper .bx-pager.bx-default-pager a {
  background: #666;
  text-indent: -9999px;
  display: block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  outline: 0;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

.bx-wrapper .bx-pager.bx-default-pager a:hover,
.bx-wrapper .bx-pager.bx-default-pager a.active {
  background: #000;
}

/* DIRECTION CONTROLS (NEXT / PREV) */
.bx-wrapper .bx-prev {
  left: 10px;
  background: url(../img/controls.png) no-repeat 0 -32px;
}

.bx-wrapper .bx-next {
  right: 10px;
  background: url(../img/controls.png) no-repeat -43px -32px;
}

.bx-wrapper .bx-prev:hover {
  background-position: 0 0;
}

.bx-wrapper .bx-next:hover {
  background-position: -43px 0;
}

.bx-wrapper .bx-controls-direction a {
  position: absolute;
  top: 50%;
  margin-top: -16px;
  outline: 0;
  width: 32px;
  height: 32px;
  text-indent: -9999px;
  z-index: 9999;
}

.bx-wrapper .bx-controls-direction a.disabled {
  display: none;
}

/* AUTO CONTROLS (START / STOP) */
.bx-wrapper .bx-controls-auto {
  text-align: center;
}

.bx-wrapper .bx-controls-auto .bx-start {
  display: block;
  text-indent: -9999px;
  width: 10px;
  height: 11px;
  outline: 0;
  background: url(../img/controls.png) -86px -11px no-repeat;
  margin: 0 3px;
}

.bx-wrapper .bx-controls-auto .bx-start:hover,
.bx-wrapper .bx-controls-auto .bx-start.active {
  background-position: -86px 0;
}

.bx-wrapper .bx-controls-auto .bx-stop {
  display: block;
  text-indent: -9999px;
  width: 9px;
  height: 11px;
  outline: 0;
  background: url(../img/controls.png) -86px -44px no-repeat;
  margin: 0 3px;
}

.bx-wrapper .bx-controls-auto .bx-stop:hover,
.bx-wrapper .bx-controls-auto .bx-stop.active {
  background-position: -86px -33px;
}

/* PAGER WITH AUTO-CONTROLS HYBRID LAYOUT */
.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-pager {
  text-align: left;
  width: 80%;
}

.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-controls-auto {
  right: 0;
  width: 35px;
}

/* IMAGE CAPTIONS */
.bx-wrapper .bx-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  background: #666 \9 ;
  background: rgba(80, 80, 80, 0.75);
  width: 100%;
}

.bx-wrapper .bx-caption span {
  color: #fff;
  font-family: Arial;
  display: block;
  font-size: 0.85em;
  padding: 10px;
}

/* CSS Document */
/*====================================================================

lv2.css

=====================================================================*/
/*--------------------------------------------------------------------/
	404
/--------------------------------------------------------------------*/
.not-found {
  background: transparent;
  position: relative;
  width: 100%;
  color: #fff;
  padding: 0;
  width: 100%;
  position: relative;
}
.not-found::before {
  background: url(../img/bg_01_pc.jpg) 50% top no-repeat;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  z-index: -1;
}
.not-found .l-cont {
  position: relative;
}
.not-found h3 {
  font-size: calc(1.6rem + 2 * (100vw - 320px) / 680);
  margin-bottom: 1.5rem;
}
@media print, screen and (min-width: 1257px) {
  .not-found h3 {
    font-size: 1.8rem;
  }
}